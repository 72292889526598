import React, { type ReactNode, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, FormControlLabel } from '@mui/material';
import { Button } from 'components/button/Button';
import { ReactComponent as ArrowRightIcon } from 'assets/icons/arrow-right.svg';
import {
  useGetProductsGendersQuery,
  useGetProductsSizesQuery
} from 'store/api/productApi';
import { ReactComponent as CloseIcon } from 'assets/icons/close-icon.svg';
import { ReactComponent as ArrowLeftIcon } from 'assets/icons/arrow-left.svg';
import { ReactComponent as CheckboxChecked } from 'assets/icons/checkbox-red.svg';
import { ReactComponent as CheckboxUnchecked } from 'assets/icons/checkbox-red-unchecked.svg';
import concatClassNames from 'utils/classNames';
import { collectIds } from 'pages/all-products/filter-utils';
import MobileCategory from 'pages/all-products/MobileCategory';
import './MobileProductsFilter.scss';
import { useGetAllCategoriesQuery } from '../../store/api/categoiesApi';
import { LOCAL_STORAGE_LOCALIZATION_KEY } from '../../i18n';
import { isEmpty, isNil } from 'lodash';

enum MobileCategoryFilters {
  Genders = 'Genders',
  Sizes = 'Sizes',
  Special = 'Special',
  Categories = 'Categories'
}

interface IMobileProductsFilterProps {
  title: string
  categoryFilters: number[]
  expandedCategory: string
  setExpandedCategory: any
  selectedFilters: any
  setSelectedFilters: any
  specialFilters: any[]
  onClose: () => void
  setCategoryFilters: any
  setSizeFilters: any
  genderFilters: any[]
  setGenderFilters: any
  selectedSpecialFilters: any[]
  setSelectedSpecialFilters: any
  setSelectedFilterDescription: any
}

const MobileProductsFilter: React.FC<IMobileProductsFilterProps> = ({
  title,
  selectedFilters,
  selectedSpecialFilters,
  categoryFilters,
  expandedCategory,
  genderFilters,
  setExpandedCategory,
  setSelectedFilters,
  specialFilters,
  onClose,
  setCategoryFilters,
  setSizeFilters,
  setGenderFilters,
  setSelectedSpecialFilters
}) => {
  const { t } = useTranslation();

  const { data: categories, isLoading: isCategoriesLoading, error: categoriesError } = useGetAllCategoriesQuery();
  const { data: genders } = useGetProductsGendersQuery({});
  const { data: sizes } = useGetProductsSizesQuery(expandedCategory, { skip: !expandedCategory });

  const [activeCategory, setActiveCategory] = useState<string | null>(null);
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);
  const [selectedSubcategories, setSelectedSubcategories] = useState<number[]>([]);
  const [hasNestedSubcategories, setHasNestedSubcategories] = useState(false);
  const [hasSelectedNestedSubcategory, setHasSelectedNestedSubcategory] = useState(null);
  const [selectAllNestedSubcategories, setSelectAllNestedSubcategories] = useState(false);

  const selectedLanguage = localStorage.getItem(LOCAL_STORAGE_LOCALIZATION_KEY) ?? 'bg';

  const handleCheckboxChange = (category: string, option: any) => {
    const isInitiallySelected = categoryFilters.includes(option.id);

    setSelectedFilters((prev: any) => {
      const optionName = option.nameEn ?? option.name;
      const isSelected = !isNil(prev[category]?.[optionName]) || isInitiallySelected;

      let updatedCategoryFilters;

      if (isSelected) {
        const { [optionName]: _, ...rest } = prev[category];
        updatedCategoryFilters = [rest];
      } else {
        updatedCategoryFilters = {
          [optionName]: { id: option.id, name: optionName }
        };
      }

      return {
        ...prev,
        [category]: updatedCategoryFilters
      };
    });
  };

  const handleCategoryClick = (categoryId: any) => {
    if (activeCategory === MobileCategoryFilters.Categories) {
      setExpandedCategory(categoryId);
      return;
    }

    setActiveCategory(categoryId);
  };

  const renderCheckboxes = useCallback(
    (categoryName: string) => {
      let options = [];
      let filters: any[] = [];
      let label = '';
      switch (categoryName) {
        case MobileCategoryFilters.Genders:
          options = genders;
          label = 'genders.';
          filters = genderFilters || [];
          break;
        case MobileCategoryFilters.Sizes:
          options = sizes || [];
          break;
        case MobileCategoryFilters.Special:
          options = specialFilters;
          filters = selectedSpecialFilters || [];
          label = '';
          break;
        default:
          options = [];
          break;
      }
      console.log(categoryName, options, filters);

      return options.map((option: any) => {
        console.log(option);
        const isInitiallySelected = filters.includes(option.id);
        const isSelected = !!selectedFilters[categoryName]?.[option.name] || isInitiallySelected;

        const text = label
          ? t(label + option.name.toLowerCase())
          : option.name;

        return (
        <FormControlLabel
          key={option.id}
          className={concatClassNames('category-filter-checkbox', isSelected ? 'selected-checkbox' : '')}
          control={
            <Checkbox
              checked={isSelected}
              onChange={() => {
                handleCheckboxChange(categoryName, option);
              }}
              checkedIcon={<CheckboxChecked/>}
              icon={<CheckboxUnchecked/>}
            />
          }
          label={text}
        />
        );
      });
    },
    [genders, genderFilters, selectedSpecialFilters, specialFilters, sizes, selectedFilters, handleCheckboxChange]);

  const renderCategories = () => (
    categories?.map((category: any) => (
      <MobileCategory
        key={category.id}
        item={category}
        onClick={() => {
          handleCategoryClick(category.id);
        }}
        className="mobile-products-filter-body-category"
        itemClassName="mobile-products-filter-body-category-title"
      />
    ))
  );

  const renderSubcategories = () => {
    const category = categories?.find((category: any) => category.id === expandedCategory);

    return category?.subcategories?.map((subcategory: any) => (
      <MobileCategory
        key={subcategory.id}
        item={subcategory}
        onClick={() => {
          if (selectedSubcategories.includes(subcategory.id)) {
            setSelectedSubcategories([]);
          } else {
            setSelectedSubcategories([subcategory.id]);
          }

          setSelectedSubcategory(subcategory.id);
        }}
        setHasNestedSubcategories={setHasNestedSubcategories}
        className="mobile-products-filter-body-category"
        itemClassName={concatClassNames('mobile-products-filter-body-category-title', selectedSubcategories.includes(subcategory.id) ? 'selected-subcategory' : '')}
      />
    ));
  };

  const renderNestedSubcategories = () => {
    const category = categories?.find((cat: any) => cat.id === expandedCategory);
    const subcategory = category?.subcategories?.find((subcat: any) => subcat.id === selectedSubcategory);

    return subcategory?.subcategories?.map((nestedSubcat: any) => {
      const isInitiallySelected = categoryFilters.includes(nestedSubcat.id);
      const isSelected = !!selectedFilters[subcategory.nameEn ?? '']?.[nestedSubcat.nameEn] || isInitiallySelected;

      return (
        <FormControlLabel
          key={nestedSubcat.id}
          className={concatClassNames('category-filter-checkbox', isSelected ? 'selected-checkbox' : '')}
          control={
            <Checkbox
              checked={isSelected}
              onChange={() => {
                setHasSelectedNestedSubcategory(nestedSubcat.id);
                handleCheckboxChange(subcategory.nameEn ?? '', nestedSubcat);
              }}
              checkedIcon={<CheckboxChecked/>}
              icon={<CheckboxUnchecked/>}
            />
          }
          label={selectedLanguage === 'bg' ? nestedSubcat?.nameBg : nestedSubcat?.nameEn}
        />
      );
    });
  };

  const applyFilters = () => {
    setExpandedCategory(null);
    const {
      [MobileCategoryFilters.Genders]: selectedGenderFilters,
      [MobileCategoryFilters.Sizes]: selectedSizeFilters,
      [MobileCategoryFilters.Special]: selectedSpecialFilters,
      ...selectedCategoryFilters
    } = selectedFilters;

    const genderIds = selectedGenderFilters && Object.values(selectedGenderFilters).map((option: any) => option.id);
    setGenderFilters(genderIds);

    const specialFilterIds = selectedSpecialFilters && Object.values(selectedSpecialFilters).map((option: any) => option.id);
    setSelectedSpecialFilters(specialFilterIds);

    const sizeIds = selectedSizeFilters && Object.values(selectedSizeFilters).map((option: any) => option.id);
    setSizeFilters(sizeIds);

    const selectedCategories = isNil(selectedCategoryFilters) || isEmpty(selectedCategoryFilters)
      ? isNil(selectedSubcategories) || isEmpty(selectedSubcategories)
        ? expandedCategory === MobileCategoryFilters.Categories || isEmpty(expandedCategory)
          ? selectedCategoryFilters
          : [expandedCategory]
        : [...selectedSubcategories]
      : collectIds(selectedCategoryFilters);

    const actualCategories = isNil(selectedCategories) || isEmpty(selectedCategories)
      ? categoryFilters
      : selectedCategories;

    console.log(activeCategory);

    setCategoryFilters(actualCategories);

    onClose();
  };

  const renderFooterIcon = (icon: ReactNode, text: string) => (
    <div className="flex footer-close align-center">
      {icon}
      <span>{text}</span>
    </div>
  );

  const getFooterButtonText = () => {
    if (activeCategory) {
      const categorySelected = selectedFilters[activeCategory] &&
        Object.values(selectedFilters[activeCategory]).some(value => value);

      return categorySelected || hasSelectedNestedSubcategory || selectAllNestedSubcategories
        ? t('common.show')
        : renderFooterIcon(
          <ArrowLeftIcon/>, t('common.back'));
    }

    return renderFooterIcon(<CloseIcon/>, t('common.close'));
  };

  const resetCategoryFilters = () => {
    setActiveCategory(null);
    setExpandedCategory(null);
    setSelectedSubcategory(null);
    setHasSelectedNestedSubcategory(null);
  };

  const onClick = () => {
    if (activeCategory) {
      const categorySelected = selectedFilters[activeCategory] &&
        Object.values(selectedFilters[activeCategory]).some(value => value);

      (selectedSubcategories.length > 0 && !hasNestedSubcategories) || categorySelected || hasSelectedNestedSubcategory || selectAllNestedSubcategories
        ? applyFilters()
        : resetCategoryFilters();

      return;
    }

    onClose();
  };

  if (isCategoriesLoading) return <div>{t('common.loading')}</div>;
  if (categoriesError) return <div>{t('common.errorLoadingCategories')}</div>;

  const renderHeader = () => {
    if (!hasNestedSubcategories || activeCategory !== MobileCategoryFilters.Categories) {
      return (
        <span className="mobile-products-filter-header-title">
          {activeCategory
            ? t(`mobileCategories.${activeCategory?.toLowerCase()}`)
            : title}
        </span>
      );
    }

    if (selectedSubcategory && hasNestedSubcategories) {
      const subcategory = categories?.find(
        (cat: any) => cat.id === expandedCategory
      )?.subcategories?.find(
        (subcat: any) => subcat.id === selectedSubcategory
      );

      const selectAllHandler = () => {
        const allNestedSubcategories = subcategory?.subcategories || [];

        const areAllSelected = allNestedSubcategories.every(
          (nestedSubcat: any) =>
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            !!selectedFilters[subcategory?.nameEn]?.[nestedSubcat.nameEn]
        );

        setSelectAllNestedSubcategories(!areAllSelected);

        const updatedFilters = allNestedSubcategories.reduce(
          (acc: any, nestedSubcat: any) => {
            acc[nestedSubcat.name] = {
              id: nestedSubcat.id,
              name: nestedSubcat.name
            };
            return acc;
          },
          {}
        );

        setSelectedFilters((prev: any) => ({
          ...prev,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          [subcategory?.nameEn]: areAllSelected ? {} : updatedFilters
        }));
      };

      return (
        <div className="flex align-center space-between full-width mobile-products-filter-header-title">
          <span>{t(`mobileCategories.${activeCategory?.toLowerCase()}`)}</span>
          <span
            onClick={selectAllHandler}
            className="select-all-filters-text"
          >
            {selectAllNestedSubcategories
              ? t('common.clearAll')
              : t('common.selectAll')}
          </span>
        </div>
      );
    }
  };

  return (
    <article className="mobile-products-filter">
      <div className="mobile-products-filter-header">
        {renderHeader()}
      </div>
      <div className="flex flex-column mobile-products-filter-body">
        {activeCategory
          ? (activeCategory === MobileCategoryFilters.Categories
              ? selectedSubcategory && hasNestedSubcategories
                ? renderNestedSubcategories()
                : expandedCategory
                  ? renderSubcategories()
                  : renderCategories()
              : <div className="flex flex-column" style={{ paddingTop: 16 }}>
                  {renderCheckboxes(activeCategory)}
                </div>)
          : [MobileCategoryFilters.Genders, MobileCategoryFilters.Sizes, MobileCategoryFilters.Special, MobileCategoryFilters.Categories].map(category => (
            <div
              key={category}
              onClick={() => {
                setActiveCategory(category);
              }}
              className="mobile-products-filter-body-category">
              <span className="flex align-center space-between mobile-products-filter-body-category-title">
                {t('mobileCategories.' + category.toLowerCase())}
                <ArrowRightIcon/>
              </span>
            </div>
            ))
        }
      </div>
      <div className="mobile-products-filter-footer">
        <Button
          onClick={onClick}
          className="mobile-products-filter-footer-cta"
        >
          {getFooterButtonText()}
        </Button>
      </div>
    </article>
  );
};

export default MobileProductsFilter;

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import concatClassNames from 'utils/classNames';
import { type IClassName } from 'types/common/Props';
import useAuthToken from 'hooks/useAuthToken';
import HomeCategories from 'components/home/HomeCategories';
import HomeProducts from 'components/home/HomeProducts';
import HomeOurMission from 'components/home/HomeOurMission';
import HomeBanners from 'components/home/HomeBanners';
import HomeBlog from 'components/home/HomeBlog';
import { ButtonColors } from 'components/button/Button';
import ResetPasswordPopup from 'components/reset-password/ResetPasswordPopup';
import { useLocation } from 'react-router-dom';
import { ROUTES } from 'routes/routes';
import { useConfirmEmailMutation } from 'store/api/userApi';
import { useNotification } from 'components/notification/Notification';
import WriteReviewPopup from 'components/review/write-review-popup/WriteReviewPopup';
import { useGetPageDetailsQuery } from 'store/api/pagesApi';
import { useLazyGetAllBannersQuery } from 'store/api/bannerApi';
import './Home.scss';
import PageSeoSection from 'components/page-seo-section/PageSeoSection';

interface IHomeProps extends IClassName {
}

const Home = ({ className }: IHomeProps) => {
  useAuthToken();

  const { t } = useTranslation();
  const location = useLocation();
  const { pathname, search } = location;
  const { showNotification } = useNotification();
  const [getAllBanners, { data: banners }] = useLazyGetAllBannersQuery();
  const { data: pageSeoData } = useGetPageDetailsQuery({ url: ROUTES.Home.path });
  const [confirmEmail] = useConfirmEmailMutation();
  const [showResetPasswordPopup, setShowResetPasswordPopup] = useState(false);
  const [resetPasswordProps, setResetPasswordProps] = useState<any>({});
  const [showWriteReviewPopup, setShowWriteReviewPopup] = useState(false);
  const [writeReviewProps, setWriteReviewProps] = useState<any>({});

  useEffect(() => {
    void getAllBanners({ onlyVisible: true });

    if (pathname === ROUTES.ResetPassword.path && search) {
      const searchParams = new URLSearchParams(search);
      const email = searchParams.get('email');
      const token = searchParams.get('token');

      if (email && token) {
        setShowResetPasswordPopup(true);
        setResetPasswordProps({ email, token });
      } else {
        console.error('Invalid reset password URL: email or token is missing.');
      }
    }

    if (pathname === ROUTES.ConfirmEmail.path && search) {
      const searchParams = new URLSearchParams(search);
      const email = searchParams.get('email');
      const token = searchParams.get('token');

      if (email && token) {
        fetchData(email, token)
          .then(() => {
            showNotification(t('notifications.confirmEmailSuccess'));
          })
          .catch((error) => {
            console.error('Failed to confirm email:', error);
            showNotification(t('notifications.confirmEmailError'));
          });
      }
    }

    if (pathname === ROUTES.AddReview.path && search) {
      const searchParams = new URLSearchParams(search);
      const sellerId = searchParams.get('sellerId');
      const sellerUsername = searchParams.get('sellerUsername');

      if (sellerId && sellerUsername) {
        setShowWriteReviewPopup(true);
        setWriteReviewProps({ sellerId, sellerUsername });
      } else {
        console.error('Invalid write review URL: sellerId or sellerUsername is missing.');
      }
    }
  }, [pathname, search]);

  const fetchData = async (email: string, token: string) => {
    await confirmEmail({ email, token });
  };

  const hasBanners = banners && banners.length > 0;

  return (
    <>
      <div className='page-seo-section-wrapper'>
        <PageSeoSection pageSeo={pageSeoData} />
      </div>
      <div className={concatClassNames(className, hasBanners ? 'home-with-banners' : '', 'home')}>
        <HomeCategories />
        <HomeProducts />
        <HomeOurMission />
        {hasBanners && <HomeBanners banners={banners} />}
        <section
          className={concatClassNames('home-blog-wrapper section-padding', hasBanners ? 'home-blog-banners-bg' : 'home-blog-no-banners-bg')}
        >
          <HomeBlog buttonColor={hasBanners ? ButtonColors.primary : ButtonColors.tertiary} />
        </section>
        {showResetPasswordPopup && (
          <ResetPasswordPopup
            open={showResetPasswordPopup}
            onClose={() => {
              setShowResetPasswordPopup(false);
            }}
            email={resetPasswordProps.email}
            token={resetPasswordProps.token}
          />
        )}
        {showWriteReviewPopup && (
          <WriteReviewPopup
            open={showWriteReviewPopup}
            onClose={() => {
              setShowWriteReviewPopup(false);
            }}
            sellerId={writeReviewProps.sellerId}
            sellerUsername={writeReviewProps.sellerUsername}
          />
        )}
      </div>
    </>
  );
};

export default Home;

import React from 'react';
import { Editor } from '@tinymce/tinymce-react';
import ErrorFormControl from 'components/forms/ErrorFormControl';
import './TextEditor.scss';

interface IGenericEditorProps {
  value: string
  name: string
  onChange: (value: string) => void
  label?: string
  error?: string
}

const TextEditor = ({ value, name, onChange, label, error }: IGenericEditorProps) => {
  return (
    <div className="text-editor">
      {label && <label htmlFor={name} className="text-editor-label">{label}</label>}
      <Editor
        apiKey="9m3astlhilzobpbgzkaltqfy3p5n3rvs25rfh83clsbu16eg"
        value={value}
        textareaName={name}
        init={{
          branding: false,
          height: 400,
          menubar: true,
          plugins:
            'print preview paste searchreplace autolink directionality visualblocks visualchars fullscreen image link media codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern',
          toolbar:
            'formatselect | bold italic underline strikethrough | forecolor backcolor blockquote | link image media | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat',
          image_advtab: true
        }}
        onEditorChange={onChange}
      />
      {error && <ErrorFormControl error={error}/>}
    </div>
  );
};

export default TextEditor;

import Dropdown, { type OptionType } from 'components/dropdown/Dropdown';
import useDropdown from 'hooks/useDropdown';
import './LanguageSwitcher.scss';
import { useI18n } from 'hooks/usei18n';

const LOCAL_STORAGE_KEY = 'i18nextLng';

const LanguageSwitcher = () => {
  const { changeLanguage } = useI18n();
  const languageMap: Record<string, number> = {
    en: 1,
    bg: 2
  };

  const languageOptions: OptionType[] = [
    { id: languageMap.en, name: 'English' },
    { id: languageMap.bg, name: 'Български' }
  ];

  const storedLanguageCode = localStorage.getItem(LOCAL_STORAGE_KEY) ?? languageMap.bg.toString();
  const defaultLanguageId = languageMap[storedLanguageCode];

  const { selectedValues, handleSelectionChange } = useDropdown(defaultLanguageId, true);

  const handleChangeLanguage = async (selectedId: any) => {
    handleSelectionChange(selectedId);
    const langCode = Object.keys(languageMap).find(key => languageMap[key] === parseInt(selectedId));

    if (langCode) {
      await changeLanguage(langCode);
    }
  };

  return (
    <Dropdown
      label=""
      options={languageOptions}
      value={selectedValues}
      onChange={handleChangeLanguage}
      multiple={false}
      className="language-switcher"
    />
  );
};

export default LanguageSwitcher;

import React from 'react';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { ReactComponent as CheckboxChecked } from 'assets/icons/checkbox-red.svg';
import { ReactComponent as CheckboxUnchecked } from 'assets/icons/checkbox-red-unchecked.svg';
import concatClassNames from 'utils/classNames';
import { type IOptionalClassName } from 'types/common/Props';

interface IFilterProps extends IOptionalClassName {
  items: any[]
  selectedItems: number[]
  setSelectedItems: any
  title?: string
}

const Filter = ({ items, selectedItems, setSelectedItems, title, className }: IFilterProps) => {
  const handleCheckboxChange = (itemId: number) => {
    if (selectedItems.includes(itemId)) {
      setSelectedItems(selectedItems.filter((id: number) => id !== itemId));
    } else {
      setSelectedItems([...selectedItems, itemId]);
    }
  };

  return (
    <div className={concatClassNames('flex flex-column', className)}>
      <FormGroup className="filter-menu">
        {title && <span className="filter-menu-title">{title}:</span>}
        {items?.map((item: any) => (
          <FormControlLabel
            key={item.id}
            control={
              <Checkbox
                checked={selectedItems.includes(item.id)}
                onChange={() => {
                  handleCheckboxChange(item.id);
                }}
                name={item.name}
                checkedIcon={<CheckboxChecked/>}
                icon={<CheckboxUnchecked/>}
              />
            }
            label={item.name}
            className={concatClassNames('filter-item', selectedItems.includes(item.id) ? 'selected' : '')}
          />
        ))}
      </FormGroup>
    </div>
  );
};

export default Filter;
